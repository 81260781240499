import React, { useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { DURATION_TIME_UNITS, DurationTimeUnit } from '@/main/app.constants';
import _ from 'lodash';
import { Select } from '@seeqdev/qomponents';
import i18next from 'i18next';

export const SUPPORTED_VALUES_BY_UNIT = {
  'DURATION_UNITS.SECONDS': [5, 10, 15, 30],
  'DURATION_UNITS.MINUTES': [1, 5, 10, 15, 30],
  'DURATION_UNITS.HOURS': [1, 2, 4, 6, 8, 12],
};
type DurationUnitKey = keyof typeof SUPPORTED_VALUES_BY_UNIT;
export type DurationValuesByUnit = { [key in DurationUnitKey]?: number[] };
const SUPPORTED_UNIT_LABELS = Object.keys(SUPPORTED_VALUES_BY_UNIT);
const SUPPORTED_UNITS = _.filter(DURATION_TIME_UNITS, (unit) => SUPPORTED_UNIT_LABELS.includes(unit.translationKey));

function getDurationValuesForUnit(
  unit: DurationTimeUnit,
  durationValuesByUnit: DurationValuesByUnit,
): { value: number; label: number }[] {
  const key = unit.translationKey as DurationUnitKey;
  return (durationValuesByUnit[key] ?? SUPPORTED_VALUES_BY_UNIT[key]).map((value) => ({
    value,
    label: value,
  }));
}

function getUnitValues(durationValuesByUnit: DurationValuesByUnit): { value: string; label: string }[] {
  return _.chain(SUPPORTED_UNITS)
    .reject((supportedUnit) => _.isEmpty(getDurationValuesForUnit(supportedUnit, durationValuesByUnit)))
    .map((supportedUnit) => ({ value: supportedUnit.translationKey, label: i18next.t(supportedUnit.translationKey) }))
    .value();
}

interface DurationSelectorProps {
  unit: DurationTimeUnit;
  value: number;
  onChange: (value: number, unit: DurationTimeUnit) => void;
  onInvalidInput: (message: string) => void;
  durationValuesByUnit: DurationValuesByUnit;
}

type Option = { value: string; label: string };
/**
 * Select time durations via dropdowns, with fixed set of options
 */
export const DurationSelector: React.FunctionComponent<DurationSelectorProps> = ({
  unit,
  value,
  onChange,
  onInvalidInput,
  durationValuesByUnit,
}) => {
  const [durationValues, setDurationValues] = useState(getDurationValuesForUnit(unit, durationValuesByUnit));
  const [unitValues, setUnitValues] = useState(getUnitValues(durationValuesByUnit));

  useEffect(() => {
    const newDurationValues = getDurationValuesForUnit(unit, durationValuesByUnit);
    setDurationValues(newDurationValues);
    setUnitValues(getUnitValues(durationValuesByUnit));
    if (!_.find(newDurationValues, { value })) {
      onInvalidInput(`Duration value ${value} is not one of the options for ${unit.translationKey}`);
    }
  }, [unit, value, durationValuesByUnit, onInvalidInput]);

  const onChangeValue = (option: Option) => {
    const newVal = _.toInteger(option.value);
    onChange(newVal, unit);
  };

  const onChangeUnit = (option: Option) => {
    const newUnit = DURATION_TIME_UNITS.find((unit) => unit.translationKey === option.value)!;
    const newValueOptions = getDurationValuesForUnit(newUnit, durationValuesByUnit);
    let newValue = value;
    if (!_.find(newValueOptions, { value })) {
      newValue = newValueOptions[0].value;
    }
    onChange(newValue, newUnit);
  };
  return (
    <FormGroup className="flexColumnContainer">
      <span data-testid="duration-selector__value">
        <Select
          extraClassNames="width-100 mr5"
          value={_.find(durationValues, { value })}
          onChange={onChangeValue}
          options={durationValues}
          menuPortalTarget={document.body}
        />
      </span>
      <span data-testid="duration-selector__unit">
        <Select
          extraClassNames="width-100"
          value={_.find(unitValues, { value: unit?.translationKey })}
          onChange={onChangeUnit}
          options={unitValues}
          menuPortalTarget={document.body}
        />
      </span>
    </FormGroup>
  );
};
